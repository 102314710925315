var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grade"},[_c('div',{staticClass:"condition"},[_c('el-form',[_c('el-form-item',{attrs:{"label":"分销员条件："}},[_c('el-radio-group',{on:{"change":_vm.changeCondition},model:{value:(_vm.ls_type),callback:function ($$v) {_vm.ls_type=$$v},expression:"ls_type"}},[_c('el-radio',{attrs:{"label":1}},[_vm._v("推广金额满足条件")]),_c('el-radio',{attrs:{"label":2}},[_vm._v("自购金额满足条件")]),_c('el-radio',{attrs:{"label":3}},[_vm._v("推广客户数满足条件")]),_c('el-radio',{attrs:{"label":4}},[_vm._v("邀请分销员数满足条件")])],1)],1)],1)],1),_c('el-table',{attrs:{"data":_vm.list,"header-cell-style":{ 'background-color': '#F8F9FA' }}},[_c('template',{slot:"empty"},[_c('No')],1),_c('el-table-column',{attrs:{"prop":"name","label":"等级名称","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.is_edit)?_c('span',[_vm._v(_vm._s(row.name))]):_c('el-input',{staticStyle:{"width":"150px"},attrs:{"size":"small"},model:{value:(row.name),callback:function ($$v) {_vm.$set(row, "name", $$v)},expression:"row.name"}})]}}])}),_c('el-table-column',{attrs:{"prop":"latitude","label":"成为条件","width":"350","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.is_edit)?_c('span',[_vm._v(" "+_vm._s(row.condition)+" ")]):_c('div',{staticClass:"rule"},[(row.type == 0)?_c('div',{staticStyle:{"flex-direction":"column"}},[_c('p',[_vm._v(_vm._s(row.condition))]),_c('p',[_vm._v(" 注：可在 "),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.$emit('tabSwich', '1')}}},[_vm._v("分销设置")]),_vm._v(" 中修改成为条件 ")],1)]):(_vm.ls_type == 1)?_c('div',[_c('p',[_vm._v("推广金额满")]),_c('el-input',{attrs:{"size":"small"},model:{value:(row.value),callback:function ($$v) {_vm.$set(row, "value", $$v)},expression:"row.value"}}),_vm._v(" 元 ")],1):(_vm.ls_type == 2)?_c('div',[_c('p',[_vm._v("自购金额满")]),_c('el-input',{attrs:{"size":"small"},model:{value:(row.value),callback:function ($$v) {_vm.$set(row, "value", $$v)},expression:"row.value"}}),_vm._v(" 元 ")],1):(_vm.ls_type == 3)?_c('div',[_c('p',[_vm._v("客户数满")]),_c('el-input',{attrs:{"size":"small"},model:{value:(row.value),callback:function ($$v) {_vm.$set(row, "value", $$v)},expression:"row.value"}}),_vm._v(" 人 ")],1):_c('div',[_vm._v(" 邀请 "),_c('el-select',{attrs:{"size":"small"},model:{value:(row.level_id),callback:function ($$v) {_vm.$set(row, "level_id", $$v)},expression:"row.level_id"}},_vm._l((_vm.inviteList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),_vm._v(" 满 "),_c('el-input',{attrs:{"size":"small"},model:{value:(row.value),callback:function ($$v) {_vm.$set(row, "value", $$v)},expression:"row.value"}}),_vm._v(" 人 ")],1)])]}}])}),_c('el-table-column',{attrs:{"prop":"scope","label":"佣金比例设置","width":"440","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.is_edit)?_c('span',[(_vm.setInfo.tier > 0)?_c('span',[_vm._v(_vm._s(("直接佣金比列（一级）" + (Number(row.commission_rate_stair)) + "%;")))]):_vm._e(),(_vm.setInfo.tier > 1)?_c('span',[_vm._v(_vm._s(("间接佣金比例（二级）" + (row.commission_rate_second) + "%;")))]):_vm._e(),(_vm.setInfo.tier > 2)?_c('span',[_vm._v(_vm._s(("次间接佣金比例（三级）" + (row.commission_rate_three) + "%;")))]):_vm._e()]):_c('div',{staticClass:"commission"},[(_vm.setInfo.tier == 0)?_c('div',{staticClass:"item",staticStyle:{"line-height":"40px"}},[_vm._v(" 暂未开启分销，请先 "),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.$emit('tabSwich', '1')}}},[_vm._v("去分销")]),_vm._v(" 设置 ")],1):_vm._e(),(_vm.setInfo.tier > 0)?_c('div',{staticClass:"item"},[_c('span',[_vm._v("直接佣金比列（一级）")]),_c('el-input',{attrs:{"size":"small"},model:{value:(row.commission_rate_stair),callback:function ($$v) {_vm.$set(row, "commission_rate_stair", $$v)},expression:"row.commission_rate_stair"}}),_vm._v(" % ")],1):_vm._e(),(_vm.setInfo.tier > 1)?_c('div',{staticClass:"item"},[_c('span',[_vm._v("间接佣金比例（二级）")]),_c('el-input',{attrs:{"size":"small"},model:{value:(row.commission_rate_second),callback:function ($$v) {_vm.$set(row, "commission_rate_second", $$v)},expression:"row.commission_rate_second"}}),_vm._v(" % ")],1):_vm._e(),(_vm.setInfo.tier > 2)?_c('div',{staticClass:"item"},[_c('span',[_vm._v("次间接佣金比例（三级）")]),_c('el-input',{attrs:{"size":"small"},model:{value:(row.commission_rate_three),callback:function ($$v) {_vm.$set(row, "commission_rate_three", $$v)},expression:"row.commission_rate_three"}}),_vm._v(" % ")],1):_vm._e()])]}}])}),_c('el-table-column',{attrs:{"prop":"status","label":"等级购买权益","width":"220","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.is_edit)?_c('span',[_vm._v("享受商品折扣"+_vm._s(row.discount)+"折")]):_c('div',{staticClass:"discount"},[_vm._v(" 商品享受折扣 "),_c('el-input',{attrs:{"size":"small"},model:{value:(row.discount),callback:function ($$v) {_vm.$set(row, "discount", $$v)},expression:"row.discount"}}),_vm._v(" 折 ")],1)]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"200","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(!scope.row.is_edit)?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":_vm.levelSubmit}},[_vm._v("保存")]):_vm._e(),(scope.row.id && scope.row.is_edit)?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.edit(scope.row, scope.$index)}}},[_vm._v("编辑")]):_vm._e(),(scope.row.type)?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.remove(scope.row.id, scope.$index)}}},[_vm._v(_vm._s(scope.row.is_edit ? '删除' : '取消'))]):_c('el-button',{attrs:{"disabled":scope.row.is_edit || scope.row.is_default,"type":"text","size":"small"},on:{"click":function($event){return _vm.remove(scope.row.id, scope.$index)}}},[_vm._v(" "+_vm._s(scope.row.is_edit ? '删除' : '取消')+" ")])]}}])})],2),_c('div',{staticClass:"add"},[_c('el-button',{attrs:{"size":"small","type":"primary","icon":"el-icon-plus"},on:{"click":_vm.add}},[_vm._v("添加等级")]),_c('p',[_vm._v("注意：分销佣金关系请确保在三级内")]),_c('p',[_vm._v(" 分销结算日期为：售后维权期结束后到账 "),_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.goSetOrder}},[_vm._v("设置售后维权期")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }